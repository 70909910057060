<template>
	<div>
		<div class="header filter">
			<!-- 해더 -->
			<div class="cartel_top">
				<div class="top top_mafi f-left">
					<div class="util">
						<button
							@click="$emit('goBack')"
							class="arrow_white"
						>
							<i class="icon-arrow-left"></i>
						</button>
					</div>
					<div class="page_tit_area">
						<h2 class="page_tit">{{  program.title }}</h2>
						<span class="page_subtit">{{  item_cartel.cartl_name }}</span>
					</div>
				</div>
			</div>
			<!-- //해더 -->
			<!-- 친구검색 -->
			<div class="serch_top">
				<div class="top top_wh">
					<div class="header_search">
						<input
							v-model="item_search.search_value"
							type="text" :placeholder="$language.common.search_member"
							maxlength="20"
						>
					</div>
					<div class="header_search_btn">
						<div class="btn_del">
							<!-- TODO : 텍스트 입력시에만 .active 로 닫기 버튼 활성화 -->
							<button
								v-if="item_search.search_value"
								class="active"
								@click="item_search.search_value = ''; getData()"
							><span class="hide">{{ $language.common.close }}</span></button>
						</div>
						<div class="util">
							<button
								@click="getData"
								class="img_button" type="submit" name="click"
							><span class="hide">{{ $language.common.search }}</span></button>
						</div>
					</div>
				</div>
			</div>
			<!-- //친구검색 -->
		</div>
		<!-- //고정해더 -->
		<!-- 컨텐츠 내용 -->
		<div class="section_wrap pt-120">
			<!-- 멤버초대 -->
			<div class="thumbnail_wrap">
				<div class="container">
					<div class="row">

						<div class="user_tit mt-10">
							<h3>{{ $language.common.friend }}</h3>
							<a>{{ friend_list_count }}</a>
						</div>

						<ul class="thumbnail_list ct_invite">
							<h3 class="hide">{{ $language.common.friend }} {{ $language.common.search }} {{ $language.common.result }}</h3>
							<li
								v-for="(item, index) in invite_friend"
								:key="'item_' + index"
							>
								<div class="ct_invite_list">
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item.profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url)"
												>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')"
												/>
											</div>
											<div class="text_area">
												<strong>{{ item.nickname }}</strong>
												<p class="textzone textzone">
													{{  item.state_message }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn">
											<button
												@click="postInvite(item)"
												class="btn_outline_blue btn_s"
												:disabled="item.invitation_possible_fg != 'Y'"
											>{{ $language.common.invite }}</button>
										</div>
									</div>
								</div>
							</li>
							<li
								v-if="invite_friend.length == 0"
							>
								<div class="list_none" style="overflow: hidden;">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{ $language.common.cannotInviteFriend }}</span>
								</div>
							</li>
						</ul>

						<div class="user_tit mt-20">
							<h3>{{ $language.common.member }}</h3>
							<a>{{ member_list_count }}</a>
						</div>

						<ul class="thumbnail_list ct_invite ">
							<h3 class="hide">{{ $language.common.friend }} {{ $language.common.search }} {{ $language.common.result }}</h3>
							<li
								v-for="(item, index) in invite_member"
								:key="'item_' + index"
							>
								<div class="ct_invite_list">
									<div class="circle_thumbnail_item">
										<div class="clear">
											<div class="picture">
												<img
													v-if="item.profle_nft_card_img_url"
													:src="$request.upload_url(item.profle_nft_card_img_url)"
												>
												<img
													v-else
													:src="require('@/assets/image/@profile_inner.png')"
													/>
											</div>
											<div class="text_area">
												<strong>{{ item.nickname }}</strong>
												<p class="textzone textzone">
													{{  item.state_message }}
												</p>
											</div>
										</div>
										<div class="ct_invite_btn">
											<button
												@click="postInvite(item)"
												class="btn_outline_blue btn_s"
												:disabled="item.invitation_possible_fg != 'Y'"
											>{{ $language.common.invite }}</button>
										</div>
									</div>
								</div>
							</li>
							<li
								v-if="invite_member.length == 0"
							>
								<div class="list_none" style="overflow: hidden;">
									<img :src="require('@/assets/image/list_none.png')" alt="Not Found History">
									<span>{{ $language.common.cannotInviteMember }}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<!-- //멤버초대 -->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'mafia057'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.invite_member
					, title: this.$language.common.invite_member
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, items_member: []
				, items_friend: []
				, item_cartel: {}
				, item_search: {}
				, all_list_count: 0
				, friend_list_count: 0
				, member_list_count: 0
			}
		}
		, computed: {
			invite_member: function(){
				let t = []
				this.items_member.filter(function(item_friend, index){

					item_friend.index = index
					if(item_friend.friend_fg == 'N'){
						t.push(item_friend)
					}
				})

				return t
			}
			, invite_friend: function(){

				let t = []
				this.items_member.filter(function(item_friend, index){

					item_friend.index = index
					if(item_friend.friend_fg == 'Y'){
						t.push(item_friend)
					}
				})

				return t
			}
		}
		, methods: {
			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_invite
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, srchtext: this.item_search.search_value
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_member = result.data.member_list
						this.all_list_count = result.data.all_list_count
						this.friend_list_count = result.data.friend_list_count
						this.member_list_count = result.data.all_list_count
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			, getMyFriend: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_friends_list
						, data: {
							member_number: this.user.member_number
							, page_number: this.$language.base.page_number
							, pagerecnum: this.$language.base.pagerecnum
						}
						, type: true
					})

					if(result.success){
						this.items_friend = result.data.friend_list
						this.$storage.setQuery(this.item_search)
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.is_search = true
					this.$bus.$emit('on', false)
				}
			}
			,getCartel: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data
						this.items_cartel_notice = result.data.notice_mttrs_list
						this.items_cartel_bbs = result.data.post_list

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, postInvite: async function(item){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_invite
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, invitation_member_number: item.member_number
						}
						, type: true
					})

					if(result.success){
						this.$bus.$emit('notify', { type: 'success', message: this.$language.common.succeedInvite })

						this.$set(this.items_member[item.index], 'invitation_possible_fg', 'N')
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		,async created() {
			this.$bus.$emit('onLoad', this.program)
			await this.getCartel()
			await this.getData()
		}

	}
</script>